import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import { obtenirPage } from "../../../js/client-es/utils"

export const requete = graphql`
  {
    pagePrevisionsCouvertureNuageuse: allPagesCompilationYaml(
      filter: { code: { eq: "previsionsCouvertureNuageuseEN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function CloudCoverForecast() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePrevisionsCouvertureNuageuse")

  return (
    <LayoutTutorielEN page={page}>
      <Section titre="Foreword">
        <p>
          You will find below the cloud cover forecast{" "}
          <strong>computed by the french model ARPEGE </strong>for France for
          the <strong>next 5 days</strong>. There is a picture every {""}
          <strong>6 hours</strong>.
        </p>
        <p>
          The <strong>black</strong> areas correspond to the{" "}
          <strong>cloudless</strong> areas: the sky is perfectly clear and it is
          possible to observe. For reliable forecasts, compare the forecasts
          from different models.
        </p>
      </Section>
      <Section titre="3 days forecast">
        <div className="conteneur-image-couverture-nuageuse">
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-6-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-12-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-18-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-24-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-30-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-36-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-42-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-48-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-54-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-60-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-66-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-72-0.png?" />
        </div>
      </Section>
    </LayoutTutorielEN>
  )
}
